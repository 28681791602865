import { FontBold, FontLight, PrimaryFont } from '../styles/font.css';
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image';

import ButtonPrimary from './ButtonPrimary';
import { Col } from 'react-bootstrap';
import KDMakeClasses from '../utilities/common';
import KDRow from './Row';
import React from 'react';
import { bgWhite } from '../styles/background.css';
import { ctaText } from '../styles/cta.css';
import { textColorWhite } from '../styles/text.css';

interface CTAProps {
  ctaImage: IGatsbyImageData;
}

export default function CallToAction({ ctaImage }: CTAProps) {
  const rowClasses = KDMakeClasses([bgWhite, 'py-5']);
  const ctaTextClasses = KDMakeClasses([
    textColorWhite,
    'text-center',
    PrimaryFont,
    FontLight,
    'd-none',
    'd-sm-block',
  ]);
  const ctaXSTextClasses = KDMakeClasses([
    textColorWhite,
    'text-center',
    PrimaryFont,
    FontLight,
    'd-block',
    'd-sm-none',
  ]);
  return (
    <KDRow className={rowClasses}>
      <Col md={1} sm={1} lg={1} xl={1} xs={1}></Col>
      <Col md={10} sm={10} lg={10} xl={10} xs={10}>
        <div className="position-relative">
          <GatsbyImage
            image={ctaImage}
            alt=""
            objectFit="cover"
            className="my-5"
          />
          <div className={ctaText}>
            <h1 className={ctaTextClasses}>
              Bingung mau desain untuk usahamu? Cari yang{' '}
              <span className={FontBold}>kualitas bagus tapi murah?</span>
            </h1>
            <p className={ctaXSTextClasses}>
              Bingung mau desain untuk usahamu? Cari yang{' '}
              <span className={FontBold}>kualitas bagus tapi murah?</span>
            </p>
            <div className="text-center">
              <ButtonPrimary href="/contact">Pesan Sekarang!</ButtonPrimary>
            </div>
          </div>
        </div>
      </Col>
      <Col md={1} sm={1} lg={1} xl={1} xs={1}></Col>
    </KDRow>
  );
}
