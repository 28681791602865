import { Col, Row } from 'react-bootstrap';
import { FontBold, PrimaryFont } from '../styles/font.css';
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image';

import Heading from './Heading';
import KDMakeClasses from '../utilities/common';
import KDRow from './Row';
import React from 'react';
import { bgWhite } from '../styles/background.css';
import { textColorGrey } from '../styles/text.css';

interface LayananUnggulanProps {
  brandingImage: IGatsbyImageData;
  medsosImage: IGatsbyImageData;
  officeImage: IGatsbyImageData;
  websiteImage: IGatsbyImageData;
}

export default function LayananUnggulan({
  brandingImage,
  medsosImage,
  officeImage,
  websiteImage,
}: LayananUnggulanProps) {
  const subHeadingClasses = KDMakeClasses(['text-center', textColorGrey]);
  const luTitleClasses = KDMakeClasses([
    FontBold,
    'text-center',
    'px-5',
    'py-2',
    PrimaryFont,
  ]);
  const luParagraphClasses = KDMakeClasses([
    'text-center',
    'px-5',
    'py-2',
    textColorGrey,
    PrimaryFont,
  ]);
  return (
    <KDRow className={bgWhite}>
      <Row className="g-0">
        <Col md={12} sm={12} lg={12} xl={12} xs={12}>
          <Heading>Layanan Unggulan Kami</Heading>
          <p className={subHeadingClasses}>
            Mulai bisnismu tanpa ragu, dengan desain berkualitas dan murah
            meriah dari kami
          </p>
        </Col>
      </Row>
      <Row className="g-0">
        <Col md={1} sm={1} lg={1} xl={1} xs={1}></Col>
        <Col md={10} sm={10} lg={10} xl={10} xs={10}>
          <Row className="g-0">
            <Col md={3} sm={3} lg={3} xl={3} xs={12}>
              <Row className="m-5">
                <GatsbyImage
                  image={medsosImage}
                  alt=""
                  objectFit="cover"
                  className="p-2"
                />
              </Row>
              <Row>
                <h5 className={luTitleClasses}>
                  Desain & Manajemen Media Sosial
                </h5>
              </Row>
              <Row>
                <p className={luParagraphClasses}>
                  Anda bisa fokus dengan hal lain, tak perlu repot, serahkan
                  kepada kami untuk membuat desain post serta penjadwalan
                  posting.
                </p>
              </Row>
            </Col>
            <Col md={3} sm={3} lg={3} xl={3} xs={12}>
              <Row className="m-5">
                <GatsbyImage
                  image={brandingImage}
                  alt=""
                  objectFit="cover"
                  className="p-2"
                />
              </Row>
              <Row>
                <h5 className={luTitleClasses}>Desain Logo & Banner UMKM</h5>
              </Row>
              <Row>
                <p className={luParagraphClasses}>
                  Branding untuk usaha kecil menengah, dengan harga terjangkau
                  dan menyesuaikan, pastinya bisa membuat brand anda lebih kuat.
                </p>
              </Row>
            </Col>
            <Col md={3} sm={3} lg={3} xl={3} xs={12}>
              <Row className="m-5">
                <GatsbyImage
                  image={websiteImage}
                  alt=""
                  objectFit="cover"
                  className="p-2"
                />
              </Row>
              <Row>
                <h5 className={luTitleClasses}>
                  Desain Website & Pembuatan Website
                </h5>
              </Row>
              <Row>
                <p className={luParagraphClasses}>
                  Desain website responsif serta pembuatan website dengan
                  berbagai CMS, dari Wordpress hingga website profesional.
                </p>
              </Row>
            </Col>
            <Col md={3} sm={3} lg={3} xl={3} xs={12}>
              <Row className="m-5">
                <GatsbyImage
                  image={officeImage}
                  alt=""
                  objectFit="cover"
                  className="p-2"
                />
              </Row>
              <Row>
                <h5 className={luTitleClasses}>Desain Office Essentials</h5>
              </Row>
              <Row>
                <p className={luParagraphClasses}>
                  Perlengkapan desain kantor seperti kartu nama, merchandise,
                  background zoom dan keperluan lainnya.
                </p>
              </Row>
            </Col>
          </Row>
        </Col>
        <Col md={1} sm={1} lg={1} xl={1} xs={1}></Col>
      </Row>
    </KDRow>
  );
}
