import { Col, Row } from 'react-bootstrap';
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image';
import {
  portofolioBottomLeftRow,
  portofolioBottomRightRow,
  portofolioImage,
  portofolioOuterRow,
  portofolioTopLeftRow,
  portofolioTopRightRow,
} from '../styles/portofolio.css';

import Heading from './Heading';
import KDMakeClasses from '../utilities/common';
import KDRow from './Row';
import { PrimaryFont } from '../styles/font.css';
import React from 'react';
import { bgWhite } from '../styles/background.css';
import { textColorGrey } from '../styles/text.css';

interface LandingPortofolioProps {
  images: IGatsbyImageData[];
}

export default function LandingPortofolio({ images }: LandingPortofolioProps) {
  const subHeadingClasses = KDMakeClasses([
    'text-center',
    textColorGrey,
    PrimaryFont,
  ]);
  const outerRowLeftClasses = KDMakeClasses([
    portofolioOuterRow,
    'g-0',
    'mr-1',
  ]);
  const outerRowRightClasses = KDMakeClasses([
    portofolioOuterRow,
    'g-0',
    'ml-1',
  ]);

  const topLeftRow = KDMakeClasses([portofolioTopLeftRow, 'g-0']);
  const bottomLeftRow = KDMakeClasses([portofolioBottomLeftRow, 'g-0']);
  const topRightRow = KDMakeClasses([portofolioTopRightRow, 'g-0']);
  const bottomRightRow = KDMakeClasses([portofolioBottomRightRow, 'g-0']);
  const imageCol = KDMakeClasses(['h-100', 'p-2']);
  return (
    <KDRow className={bgWhite}>
      <Row className="g-0">
        <Col md={1} sm={1} lg={1} xl={1} xs={1}></Col>
        <Col md={10} sm={10} lg={10} xl={10} xs={10}>
          <Heading>Portofolio</Heading>
          <p className={subHeadingClasses}>
            Dipercaya ratusan UMKM di seluruh Indonesia
          </p>
        </Col>
        <Col md={1} sm={1} lg={1} xl={1} xs={1}></Col>
      </Row>
      <Row className="g-0">
        <Col md={1} sm={1} lg={1} xl={1} xs={1}></Col>
        <Col md={10} sm={10} lg={10} xl={10} xs={10}>
          <Row className="g-0">
            <Col md={6} sm={6} lg={6} xl={6} xs={12}>
              <Row className={outerRowLeftClasses}>
                <Row className={topLeftRow}>
                  <Col md={8} sm={8} lg={8} xl={8} xs={8} className={imageCol}>
                    <GatsbyImage
                      image={images[0]}
                      alt=""
                      objectFit="cover"
                      className={portofolioImage}
                    />
                  </Col>
                  <Col md={4} sm={4} lg={4} xl={4} xs={4} className={imageCol}>
                    <GatsbyImage
                      image={images[1]}
                      alt=""
                      objectFit="cover"
                      className={portofolioImage}
                    />
                  </Col>
                </Row>
                <Row className={bottomLeftRow}>
                  <Col md={4} sm={4} lg={4} xl={4} xs={4} className={imageCol}>
                    <GatsbyImage
                      image={images[2]}
                      alt=""
                      objectFit="cover"
                      className={portofolioImage}
                    />
                  </Col>
                  <Col md={8} sm={8} lg={8} xl={8} xs={8} className={imageCol}>
                    <GatsbyImage
                      image={images[3]}
                      alt=""
                      objectFit="cover"
                      className={portofolioImage}
                    />
                  </Col>
                </Row>
              </Row>
            </Col>
            <Col md={6} sm={6} lg={6} xl={6} xs={12}>
              <Row className={outerRowRightClasses}>
                <Row className={topRightRow}>
                  <Col md={4} sm={4} lg={4} xl={4} xs={4} className={imageCol}>
                    <GatsbyImage
                      image={images[4]}
                      alt=""
                      objectFit="cover"
                      className={portofolioImage}
                    />
                  </Col>
                  <Col md={8} sm={8} lg={8} xl={8} xs={8} className={imageCol}>
                    <GatsbyImage
                      image={images[5]}
                      alt=""
                      objectFit="cover"
                      className={portofolioImage}
                    />
                  </Col>
                </Row>
                <Row className={bottomRightRow}>
                  <Col md={8} sm={8} lg={8} xl={8} xs={8} className={imageCol}>
                    <GatsbyImage
                      image={images[6]}
                      alt=""
                      objectFit="cover"
                      className={portofolioImage}
                    />
                  </Col>
                  <Col md={4} sm={4} lg={4} xl={4} xs={4} className={imageCol}>
                    <GatsbyImage
                      image={images[7]}
                      alt=""
                      objectFit="cover"
                      className={portofolioImage}
                    />
                  </Col>
                </Row>
              </Row>
            </Col>
          </Row>
        </Col>
        <Col md={1} sm={1} lg={1} xl={1} xs={1}></Col>
      </Row>
    </KDRow>
  );
}
