import { Col, Row } from 'react-bootstrap';
import { FontBold, PrimaryFont } from '../styles/font.css';
import { backgroundSecondary, bgWhite } from '../styles/background.css';
import { testimoniBox, testimoniQuoteIcon } from '../styles/testimoni.css';
import { textColorGrey, textColorPrimary } from '../styles/text.css';

import Heading from './Heading';
import KDMakeClasses from '../utilities/common';
import KDRow from './Row';
import React from 'react';

export default function Testimoni() {
  const subHeadingClasses = KDMakeClasses([
    'text-center',
    textColorGrey,
    PrimaryFont,
  ]);
  const testimonialBoxClasses = KDMakeClasses([bgWhite, testimoniBox, 'm-3']);
  const testimonialQuoteIconClasses = KDMakeClasses([
    textColorPrimary,
    FontBold,
    PrimaryFont,
    testimoniQuoteIcon,
    'm-0',
  ]);
  const testimonials = [
    {
      name: 'Arif Khoirudin',
      content:
        'Cepat dan lebih dari ekspektasi, bisa langsung diberikan contoh penggunaannya.',
      avatar: '',
    },
    {
      name: 'Arif Khoirudin',
      content:
        'Cepat dan lebih dari ekspektasi, bisa langsung diberikan contoh penggunaannya.',
      avatar: '',
    },
    {
      name: 'Arif Khoirudin',
      content:
        'Cepat dan lebih dari ekspektasi, bisa langsung diberikan contoh penggunaannya.',
      avatar: '',
    },
  ];
  return (
    <KDRow className={backgroundSecondary}>
      <Row className="g-0">
        <Col md={1} sm={1} lg={1} xl={1} xs={1}></Col>
        <Col md={10} sm={10} lg={10} xl={10} xs={10}>
          <Heading>Testimoni</Heading>
          <p className={subHeadingClasses}>
            Proses mudah, cepat, murah, dan tinggal terima beres!
          </p>
        </Col>
        <Col md={1} sm={1} lg={1} xl={1} xs={1}></Col>
      </Row>
      <Row className="g-0 pb-5">
        <Col md={1} sm={1} lg={1} xl={1} xs={1}></Col>
        <Col md={10} sm={10} lg={10} xl={10} xs={10}>
          <Row className="g-0">
            {testimonials.map((testi) => (
              <Col md={6} sm={6} lg={6} xl={6} xs={12}>
                <Row className={testimonialBoxClasses}>
                  <Col md={8} sm={8} lg={8} xl={8} xs={8}>
                    <Row className="w-100 g-0">
                      <h2 className={testimonialQuoteIconClasses}>“</h2>
                    </Row>
                    <Row className="w-100 g-0 mx-4">
                      <p className={FontBold}>{testi.content}</p>
                    </Row>
                    <Row className="w-100 g-0 mx-4">
                      <p className={textColorGrey}>{testi.name}</p>
                    </Row>
                  </Col>
                  <Col md={4} sm={4} lg={4} xl={4} xs={4}></Col>
                </Row>
              </Col>
            ))}
          </Row>
        </Col>
        <Col md={1} sm={1} lg={1} xl={1} xs={1}></Col>
      </Row>
    </KDRow>
  );
}
