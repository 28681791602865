import { Col, Row } from 'react-bootstrap';
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image';
import { headingStyles, textColorGrey } from '../styles/text.css';

import KDMakeClasses from '../utilities/common';
import KDRow from './Row';
import { PrimaryFont } from '../styles/font.css';
import React from 'react';
import { bgWhite } from '../styles/background.css';

interface CaraOrderProps {
  orderImage: IGatsbyImageData;
  orderIcon1: IGatsbyImageData;
  orderIcon2: IGatsbyImageData;
  orderIcon3: IGatsbyImageData;
  orderIcon4: IGatsbyImageData;
  orderIcon5: IGatsbyImageData;
  orderIcon6: IGatsbyImageData;
  orderIcon7: IGatsbyImageData;
}

export default function CaraOrder({
  orderImage,
  orderIcon1,
  orderIcon2,
  orderIcon3,
  orderIcon4,
  orderIcon5,
  orderIcon6,
  orderIcon7,
}: CaraOrderProps) {
  const subHeadingClasses = KDMakeClasses([textColorGrey]);
  const steps = [
    {
      title: '1. Kontak Kami',
      content: 'Caranya gampang kok, melalui WhatsApp atau melalui order form.',
      icon: orderIcon1,
    },
    {
      title: '2. Budgeting',
      content: 'Sertakan kisaran budget yang anda inginkan.',
      icon: orderIcon2,
    },
    {
      title: '3. Meeting Detail Desain',
      content:
        'Kami akan menghubungi anda lebih lanjut untuk membahas detail pekerjaan.',
      icon: orderIcon3,
    },
    {
      title: '4. Pengiriman Invoice dan Timeline Kerja',
      content:
        'Kami akan mengajukan invoice / penawaran serta perkiraan waktu pengerjaan.',
      icon: orderIcon4,
    },
    {
      title: '5. Persetujuan & DP',
      content:
        'Jika sudah setuju, lakukan pembayaran awal dan kita lanjut ke proses pengerjaan.',
      icon: orderIcon5,
    },
    {
      title: '6. Final Preview',
      content: 'Checking akhir / revisi (gratis 1x revisi minor).',
      icon: orderIcon6,
    },
    {
      title: '7. Pesanan Dikirim',
      content: 'Selesai, pelunasan dan penyerahan hasil desain.',
      icon: orderIcon7,
    },
  ];
  return (
    <KDRow className={bgWhite}>
      <Row className="g-0">
        <Col md={1} sm={1} lg={1} xl={1} xs={1}></Col>
        <Col md={10} sm={10} lg={10} xl={10} xs={10}>
          <Row className="g-0 py-4">
            <Col md={6} sm={6} lg={6} xl={6} xs={12}></Col>
            <Col md={6} sm={6} lg={6} xl={6} xs={12}>
              <h2 className={PrimaryFont + ' ' + headingStyles}>
                Cara Order Desain
              </h2>
              <p className={subHeadingClasses}>
                Proses mudah, cepat, murah, dan tinggal terima beres!
              </p>
            </Col>
          </Row>
        </Col>
        <Col md={1} sm={1} lg={1} xl={1} xs={1}></Col>
      </Row>
      <Row className="g-0">
        <Col md={1} sm={1} lg={1} xl={1} xs={1}></Col>
        <Col md={10} sm={10} lg={10} xl={10} xs={10}>
          <Row className="g-0">
            <Col md={6} sm={6} lg={6} xl={6} xs={12}>
              <GatsbyImage
                image={orderImage}
                alt=""
                objectFit="cover"
                className="w-75 m-3"
              />
            </Col>
            <Col md={6} sm={6} lg={6} xl={6} xs={12}>
              {steps.map((step) => (
                <Row className="py-2">
                  <Col md={2} sm={2} lg={2} xl={2} xs={2}>
                    <GatsbyImage
                      image={step.icon}
                      alt=""
                      objectFit="cover"
                      className="m-2 my-0 d-none d-sm-block"
                    />
                    <GatsbyImage
                      image={step.icon}
                      alt=""
                      objectFit="cover"
                      className="my-0 d-block d-sm-none"
                    />
                  </Col>
                  <Col md={10} sm={10} lg={10} xl={10} xs={10}>
                    <Row className="g-0 w-100">
                      <Col>
                        <h3 className={PrimaryFont}>{step.title}</h3>
                      </Col>
                    </Row>
                    <Row className="g-0 w-100">
                      <Col>
                        <p className={subHeadingClasses}>{step.content}</p>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              ))}
            </Col>
          </Row>
        </Col>
        <Col md={1} sm={1} lg={1} xl={1} xs={1}></Col>
      </Row>
    </KDRow>
  );
}
