import * as React from 'react';

import { graphql, type PageProps } from 'gatsby';

import KDContainer from '../components/Container';
import { KDHead } from '../components/Head';
import KDNav from '../components/Navbar';
import LandingPortofolio from '../components/LandingPortofolio';
import { IGatsbyImageData } from 'gatsby-plugin-image';
import LayananUnggulan from '../components/LayananUnggulan';
import { FloatingWhatsApp } from 'react-floating-whatsapp';
import Testimoni from '../components/Testimoni';
import Jumbotron from '../components/Jumbotron';
import CaraOrder from '../components/CaraOrder';
import CallToAction from '../components/CallToAction';
import KDFooter from '../components/Footer';

const IndexPage = ({
  data,
}: PageProps<Queries.LandingPortofolioQueryQuery>) => {
  let images: IGatsbyImageData[] = new Array();
  data.portofolio.edges.forEach(function (image) {
    images.push(image.node.childImageSharp.gatsbyImageData);
  });

  const luBrandingImage =
    data.lubranding.edges[0].node.childImageSharp.gatsbyImageData;
  const luMedsosImage =
    data.lumedsos.edges[0].node.childImageSharp.gatsbyImageData;
  const luOfficeImage =
    data.luoffice.edges[0].node.childImageSharp.gatsbyImageData;
  const luWebsiteImage =
    data.luwebsite.edges[0].node.childImageSharp.gatsbyImageData;
  const orderImage = data.kdorder.edges[0].node.childImageSharp.gatsbyImageData;

  const iconOrder1 =
    data.kdiconorder1.edges[0].node.childImageSharp.gatsbyImageData;
  const iconOrder2 =
    data.kdiconorder2.edges[0].node.childImageSharp.gatsbyImageData;
  const iconOrder3 =
    data.kdiconorder3.edges[0].node.childImageSharp.gatsbyImageData;
  const iconOrder4 =
    data.kdiconorder4.edges[0].node.childImageSharp.gatsbyImageData;
  const iconOrder5 =
    data.kdiconorder5.edges[0].node.childImageSharp.gatsbyImageData;
  const iconOrder6 =
    data.kdiconorder6.edges[0].node.childImageSharp.gatsbyImageData;
  const iconOrder7 =
    data.kdiconorder7.edges[0].node.childImageSharp.gatsbyImageData;

  const ctaImage = data.cta.edges[0].node.childImageSharp.gatsbyImageData;

  return (
    <main>
      <KDContainer>
        <KDNav />
        <Jumbotron
          image={data.assets.edges[0].node.childImageSharp.gatsbyImageData}
        />
        <LandingPortofolio images={images} />
        <LayananUnggulan
          brandingImage={luBrandingImage}
          websiteImage={luWebsiteImage}
          officeImage={luOfficeImage}
          medsosImage={luMedsosImage}
        />
        <CaraOrder
          orderImage={orderImage}
          orderIcon1={iconOrder1}
          orderIcon2={iconOrder2}
          orderIcon3={iconOrder3}
          orderIcon4={iconOrder4}
          orderIcon5={iconOrder5}
          orderIcon6={iconOrder6}
          orderIcon7={iconOrder7}
        />
        <Testimoni />
        <CallToAction ctaImage={ctaImage} />
        <KDFooter />
      </KDContainer>
      <FloatingWhatsApp
        accountName="Keshwari Design Studio"
        phoneNumber="+6283840896118"
        statusMessage="Online"
        chatMessage="Halo, Ada yang bisa kami bantu?"
      />
    </main>
  );
};

export default IndexPage;
export const LandingPortofolioQuery = graphql`
  query LandingPortofolioQuery {
    portofolio: allFile(
      limit: 9
      filter: { sourceInstanceName: { eq: "images" } }
    ) {
      edges {
        node {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    }
    assets: allFile(
      limit: 1
      filter: {
        sourceInstanceName: { eq: "assets" }
        name: { eq: "kd-jumbotron" }
      }
    ) {
      edges {
        node {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    }
    lubranding: allFile(
      limit: 1
      filter: {
        sourceInstanceName: { eq: "assets" }
        name: { eq: "kd-assets-lu-branding" }
      }
    ) {
      edges {
        node {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    }
    lumedsos: allFile(
      limit: 1
      filter: {
        sourceInstanceName: { eq: "assets" }
        name: { eq: "kd-assets-lu-medsos" }
      }
    ) {
      edges {
        node {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    }
    luoffice: allFile(
      limit: 1
      filter: {
        sourceInstanceName: { eq: "assets" }
        name: { eq: "kd-assets-lu-office" }
      }
    ) {
      edges {
        node {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    }
    luwebsite: allFile(
      limit: 1
      filter: {
        sourceInstanceName: { eq: "assets" }
        name: { eq: "kd-assets-lu-website" }
      }
    ) {
      edges {
        node {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    }
    kdorder: allFile(
      limit: 1
      filter: { sourceInstanceName: { eq: "assets" }, name: { eq: "kd-order" } }
    ) {
      edges {
        node {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    }

    kdiconorder1: allFile(
      limit: 1
      filter: {
        sourceInstanceName: { eq: "assets" }
        name: { eq: "kd-icon-order1" }
      }
    ) {
      edges {
        node {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    }
    kdiconorder2: allFile(
      limit: 1
      filter: {
        sourceInstanceName: { eq: "assets" }
        name: { eq: "kd-icon-order2" }
      }
    ) {
      edges {
        node {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    }
    kdiconorder3: allFile(
      limit: 1
      filter: {
        sourceInstanceName: { eq: "assets" }
        name: { eq: "kd-icon-order3" }
      }
    ) {
      edges {
        node {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    }
    kdiconorder4: allFile(
      limit: 1
      filter: {
        sourceInstanceName: { eq: "assets" }
        name: { eq: "kd-icon-order4" }
      }
    ) {
      edges {
        node {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    }
    kdiconorder5: allFile(
      limit: 1
      filter: {
        sourceInstanceName: { eq: "assets" }
        name: { eq: "kd-icon-order5" }
      }
    ) {
      edges {
        node {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    }
    kdiconorder6: allFile(
      limit: 1
      filter: {
        sourceInstanceName: { eq: "assets" }
        name: { eq: "kd-icon-order6" }
      }
    ) {
      edges {
        node {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    }
    kdiconorder7: allFile(
      limit: 1
      filter: {
        sourceInstanceName: { eq: "assets" }
        name: { eq: "kd-icon-order7" }
      }
    ) {
      edges {
        node {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    }
    cta: allFile(
      limit: 1
      filter: {
        sourceInstanceName: { eq: "assets" }
        name: { eq: "kd-cta-bg" }
      }
    ) {
      edges {
        node {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    }
  }
`;

export const Head = () => <KDHead />;
