import { Button } from 'react-bootstrap';
import { Link } from 'gatsby';
import React from 'react';
import { ReactNode } from 'react';

type ButtonPrimaryProps = {
  children: ReactNode;
  href: string;
};

export default function ButtonPrimary({ children, href }: ButtonPrimaryProps) {
  return (
    <Link to={href}>
      <Button>{children}</Button>
    </Link>
  );
}
