import { PrimaryFont } from '../styles/font.css';
import React from 'react';
import { ReactNode } from 'react';
import { headingStyles } from '../styles/text.css';

type HeadingProps = {
  children: ReactNode;
};

export default function Heading({ children }: HeadingProps) {
  return (
    <div className="d-flex justify-content-center align-self-center">
      <h2 className={PrimaryFont + ' ' + headingStyles}> {children} </h2>
    </div>
  );
}
