import { Col, Row } from 'react-bootstrap';
import { FontBold, FontNormal, PrimaryFont } from '../styles/font.css';
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image';
import {
  jumbotronImage,
  jumbotronTextRow,
  jumbotronTitle,
} from '../styles/jumbotron.css';
import { textColorGrey, textColorPrimary } from '../styles/text.css';

import ButtonPrimary from './ButtonPrimary';
import KDMakeClasses from '../utilities/common';
import KDRow from './Row';
import React from 'react';
import { backgroundSecondary } from '../styles/background.css';

interface JumbotronProps {
  image: IGatsbyImageData;
}

const jumbotronTitleClasses = KDMakeClasses([
  PrimaryFont,
  jumbotronTitle,
  FontBold,
]);
const jumbotronSubtitleClasses = KDMakeClasses([
  'mt-2',
  'pt-2',
  textColorGrey,
  FontNormal,
  'pb-2',
]);
const jumbotronRowClasses = KDMakeClasses([
  jumbotronTextRow,
  'd-flex',
  'flex-wrap',
  'align-content-center',
  'mt-4',
]);
export default function Jumbotron({ image }: JumbotronProps) {
  return (
    <KDRow className={backgroundSecondary}>
      <Col sm={1} lg={1} xl={1} xs={1}></Col>
      <Col md={4} sm={4} lg={4} xl={4} xs={10}>
        <Row className={jumbotronRowClasses}>
          <h1 className={jumbotronTitleClasses}>
            <span className={textColorPrimary}>Tidak perlu</span>
            <br />
            hire desainer <br />
            grafis mahal!
          </h1>
          <h5 className={jumbotronSubtitleClasses}>
            Percayakan kebutuhan grafis anda kepada kami. Bisa buat website
            juga!
          </h5>
          <ButtonPrimary href="/contact">Hubungi sekarang!</ButtonPrimary>
        </Row>
      </Col>
      <Col sm={1} lg={1} xl={1} xs={1}></Col>
      <Col sm={1} lg={1} xl={1} xs={1}></Col>
      <Col md={4} sm={4} lg={4} xl={4} xs={10}>
        <GatsbyImage
          image={image}
          alt=""
          objectFit="cover"
          className={jumbotronImage}
        />
      </Col>
      <Col sm={1} lg={1} xl={1} xs={1}></Col>
    </KDRow>
  );
}
